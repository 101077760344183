<template>
  <div class="overlay-box internal-menu menu-holder card bg-light text-dark">
    <div class="vertical-center">
      <a href="/users" class="list-item" v-bind:class="[menuState('user,manager,agent')]">Users</a>
      <a href="/business_units" class="list-item" v-bind:class="[menuState('business_unit')]" v-if="forInternal">Business Units</a>
      <a href="/tags" class="list-item" v-bind:class="[menuState('tags')]" v-if="['Admin', 'Manager'].includes(user.role)">Ticket Tags</a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: this.$userData.user,
        forInternal: this.$userData.user.role === 'Admin'
      }
    },
    methods: {
      menuState: function(val){
          return val.split(',').some(v => this.$route.name.includes(v)) ? 'active' : ''
      }
    }
  }
</script>
