import Vue from 'vue'
import html2canvas from 'html2canvas'

document.addEventListener('DOMContentLoaded', () => {
  // Declarations
  const app = document.getElementById("mainDiv")
  const wrapper = document.createElement("div")

  // Hide plugin for integration pages
  if(app || window.location.pathname.startsWith("/integrations") || !Vue.prototype.$userData || !Vue.prototype.$userData.authToken)
    wrapper.classList.add("force-hide")

  // Main process
  wrapper.classList.add("ticketing-wrapper")
  wrapper.addEventListener("click", () => {
    var options = 'scrollbars=yes,resizable=yes,status=no,location=no,addressbar=no,toolbar=no,menubar=no,width=500,height=600,left=100,top=100';
    window.open("/integrations/tickets/new", "ITS - New Ticket", options)
    window.onmessage = function (e) {
      if(e && e.data && e.data.ticket && e.data.ticket.ticketNumber){
        // Call alert function
        Vue.prototype.$alertify({
          group: 'notification',
          title: `Ticket ${e.data.ticket.ticketNumber} - ${e.data.ticket.issue}`,
          type: 'success',
          text: e.data.ticketMessage
        })
      }
    };
    captureScreenshot(app)
  })
  wrapper.appendChild(
      createElementWithClass("i", "fas fa-send")
  )

  // Append all main node
  app.appendChild(wrapper)
}, false)


function createElementWithClass(type, className) {
  const element = document.createElement(type);
  element.className = className
  return element;
}

function captureScreenshot(app){
  html2canvas(app).then(function(canvas) {
    var file = "screenshot-" + (new Date).toISOString()
    saveAs(canvas.toDataURL(), file + '.png');
  });
}

function saveAs(uri, filename){
    var link = document.createElement('a')
    if (typeof link.download === 'string') {
        link.href = uri
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } else {
        window.open(uri)
    }
}
