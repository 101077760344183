<template>
  <div class="p-5 text-center text-dark bg-light">
    <h1 class="text-jumbotron"><i class="fas fa-handshake-alt-slash"></i></h1>
    We encountered a problem while connecting to the backend. Please wait as we try to reconnect in a moment.
  </div>
</template>

<script>
  export default {
  }
</script>
