<template>
  <div class="overlay-box main-menu menu-holder card bg-light text-dark">
    <div class="vertical-center">
      <a href="/" class="list-item" v-bind:class="[menuState('home,ticket')]">Tickets</a>
      <!-- <a href="/statistics" class="list-item" v-bind:class="[menuState('statistic')]" v-if="user.role === 'Customer'">Statistics</a> -->
      <a href="/reports" class="list-item" v-bind:class="[menuState('report')]">Reports</a>
    </div>
    <div class="vertical-center border-top" v-if="['Admin', 'Manager'].includes(user.role)">
      <a href="/clients" class="list-item" v-bind:class="[menuState('client')]">Clients</a>
      <a href="/customers" class="list-item" v-bind:class="[menuState('customer')]">Customers</a>
    </div>
    <div class="vertical-center border-top" v-if="['Admin', 'Manager'].includes(user.role)">
      <a href="/users" class="list-item" v-bind:class="[menuState('user,manager,agent')]">Users</a>
      <a href="/tags" class="list-item" v-bind:class="[menuState('tags')]">Ticket Tags</a>
      <a href="/business_units" class="list-item" v-if="['Admin'].includes(user.role)" v-bind:class="[menuState('business_unit')]">Business Units</a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: this.$userData.user
      }
    },
    methods: {
      menuState: function(val){
        return val.split(',').some(v => this.$route.name.includes(v)) ? 'active' : ''
      }
    }
  }
</script>
