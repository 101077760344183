import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/Tickets/Tickets.vue')
  },
  {
    path: '/list',
    name: 'my_list',
    component: () => import('../components/Tickets/MyList.vue')
  },
  {
    path: '/tickets/',
    name: 'tickets',
    component: () => import('../components/Tickets/Tickets.vue')
  },
  {
    path: '/tickets/new',
    name: 'new_ticket',
    component: () => import('../components/Tickets/NewTicket.vue')
  },
  {
    path: '/tickets/import',
    name: 'import_tickets',
    component: () => import('../components/Tickets/ImportTicket.vue')
  },
  {
    path: '/tickets/:ticketNumber',
    name: 'ticket',
    props: true,
    component: () => import('../components/Tickets/Ticket.vue')
  },
  {
    path: '/issues',
    name: 'issues',
    component: () => import('../components/Tickets/Issues.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../components/Clients/Clients.vue')
  },
  {
    path: '/clients/new',
    name: 'new_client',
    component: () => import('../components/Clients/NewClient.vue')
  },
  {
    path: '/clients/:code',
    name: 'client',
    props: true,
    component: () => import('../components/Clients/Client.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../components/Users/Customers/Customers.vue')
  },
  {
    path: '/customers/new',
    name: 'new_customer',
    props: (route) => ({
      forManagers: true,
      ...route.params
    }),
    component: () => import('../components/Users/NewUser.vue')
  },
  {
    path: '/customers/:_id',
    name: 'customer',
    props: true,
    component: () => import('../components/Users/Customers/Customer.vue')
  },
  {
    path: '/users',
    name: 'users',
    props: true,
    component: () => import('../components/Users/Users.vue')
  },
  {
    path: '/users/new',
    name: 'new_user',
    props: (route) => ({
      forManagers: false,
      ...route.params
    }),
    component: () => import('../components/Users/NewUser.vue')
  },
  {
    path: '/users/:_id',
    name: 'user',
    props: true,
    component: () => import('../components/Users/User.vue')
  },
  {
    path: '/business_units',
    name: 'business_units',
    component: () => import('../components/BusinessUnits/BusinessUnits.vue')
  },
  {
    path: '/business_units/new',
    name: 'new_business_unit',
    props: true,
    component: () => import('../components/BusinessUnits/NewBusinessUnit.vue')
  },
  {
    path: '/business_units/:code',
    name: 'business_unit',
    props: true,
    component: () => import('../components/BusinessUnits/BusinessUnit.vue')
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('../components/Tags/Tags.vue')
  },
  {
    path: '/tags/new',
    name: 'new_tag',
    props: true,
    component: () => import('../components/Tags/NewTag.vue')
  },
  {
    path: '/tags/:code',
    name: 'tag',
    props: true,
    component: () => import('../components/Tags/Tag.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/Login/Login.vue')
  },
  {
    path: '/password-reset/:token',
    name: 'password_reset',
    props: true,
    component: () => import('../components/Login/PasswordReset.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../components/Reports/Reports.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../components/Dashboard/Dashboard.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/Users/Profile/Profile.vue')
  },
  {
    path: '/chats',
    name: 'chats',
    component: () => import('../components/Chats/Chats.vue')
  },
  {
    path: '/bxi/login',
    name: 'login_with_bxi',
    props: (route) => ({
      disableCounters: true,
      ...route.params
    }),
    component: () => import('../components/BXIAuth/Login.vue')
  },
  // {
  //   path: '/integrations/tickets/new',
  //   name: 'new_integration_ticket',
  //   props: (route) => ({
  //     disableCounters: true,
  //     ...route.params
  //   }),
  //   component: () => import('../components/Plugins/Tickets/NewTicket.vue')
  // },
  {
    path: '*',
    name: '404',
    component: () => import('../components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (from === VueRouter.START_LOCATION && Vue.prototype.$userData) {
    Vue.prototype.$http.get(
        `${Vue.prototype.$apiEndpoint}/v1/tags?isInternal=${Vue.prototype.$internalMode}&override=true`,
        {
          headers: {
            'Authorization': Vue.prototype.$userData.authToken,
            'Access-Control-Allow-Origin' : '*',
          }
        }
      ).then(response => {
        Vue.prototype.$labels = response.data.tags.filter(r => r.type === "label").map(r => {
          return {key: r._id, text: r.name, value: r.code, record: r}
        })
        Vue.prototype.$issueClassifications = response.data.tags.filter(r => r.type === "issueClassification").map(r => {
          return {key: r._id, text: r.name, value: r.code, record: r}
        })
        next()
      }).catch(error => {
        console.log(error)
        next()
      });
  } else {
    next();
  }
})

export default router
