import Vue from 'vue'
import { io } from 'socket.io-client'

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection(room) {
    this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT, {
      auth: {
        user: Vue.prototype.$userData.user,
        room: room || null,
        connection_feedback: false
      }
    })

    this.socket.on('broadcast notifications', (notifications) => {
      let i = 0
      let muted = false
      notifications.forEach(notification => {
        muted = notifications.length > 1 && i > 0
        i = i + 1
        Vue.prototype.$alertify({
          group: 'notification',
          duration: 10000,
          title: notification.content.title,
          type: muted ? 'muted' : 'alert',
          text: notification.content.simplifiedMessage || notification.content.message
        })
        Vue.prototype.$sleep(10000)
      })
    })
    this.socket.on('broadcast clients', (clients) => {
      Vue.prototype.$onlineClients = clients
    })

    return this.socket
  }

  sendChat(room, data){
    this.socket.emit('send chat', {
      room: room,
      data: data
    })
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

}

export default new SocketioService();
