<template>
  <div class="overlay-box clients-menu menu-holder card bg-light text-dark">
    <div class="vertical-center">
      <a href="/clients" class="list-item" v-bind:class="[menuState('client')]">Clients</a>
      <a href="/customers" class="list-item" v-bind:class="[menuState('customer')]">Customers</a>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      menuState: function(val){
          return val.split(',').some(v => this.$route.name.includes(v)) ? 'active' : ''
      }
    }
  }
</script>
