<template>
  <div>
    <modal
      name="offline-modal"
      :transition="`fade`"
      :width="`400px`"
      :height="`auto`"
      :scrollable="false"
      :clickToClose="false"
    >
      <OfflineModalComponent></OfflineModalComponent>
    </modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import SocketioService from '../../services/socketio.service.js';
  import OfflineModalComponent from './OfflineModal.vue'

  export default {
    components: {
      OfflineModalComponent
    },
    data() {
      return {
        notifications: [],
        interval: {},
        delay: 5000,
        totalDelay: 5000,
        loadTimestamp: new Date(),
        timeDiff: 60 * 60 * 1000 * 0.5, // 15 mins of inactivity
        errors: 0,
        sessionAlertFlag: localStorage.getObject('sessionAlertFlag') || 1,
        path: '',
        userData: localStorage.getObject('userData')
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      validateInterval: function(){
        if(!this.$userData.user)
          clearInterval(this.interval)
        if(this.errors > 3){
          this.errors = 0
          clearInterval(this.interval)
          this.initiateInterval(1000, false)
          this.$modal.show('offline-modal')
        }
        let currentTimeStamp = new Date()
        if ((currentTimeStamp - this.loadTimestamp) > this.timeDiff) {
          clearInterval(this.interval)
        }
      },
      async fetchNotifications(){
        this.validateInterval()
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/notifications/monitoring?id=${this.$userData.user._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response){
            this.errors = 0
            this.$modal.hide('offline-modal')
          }
          this.notifications = response.data.notifications
          this.displayNotifications()
        } catch (error) {
          this.errors += 1
          console.log(error)
        }
      },
      async displayNotifications(){
        let i = 0
        let muted = false
        this.notifications.forEach(notification => {
          muted = this.notifications.length > 1 && i > 0
          i = i + 1
          this.$alertify({
            group: 'notification',
            duration: 6000,
            title: notification.content.title,
            type: muted ? 'muted' : 'alert',
            text: notification.content.simplifiedMessage || notification.content.message
          })
          this.$sleep(3000)
        })
        this.notifications = []
      },
      initiateInterval: async function(increment, reset){
        // this.totalDelay = reset ? this.delay : increment + this.totalDelay
        // this.interval = setInterval(() => this.fetchNotifications(), this.totalDelay)
        await this.$sleep(5000);
        this.interval = setInterval(() => {
          if(!this.$checkSession(false, increment, reset))
            this.logout()
          if(this.$checkSession(true) && this.$userData && this.sessionAlertFlag == 1){
            this.$alertify({
              group: 'notification',
              title: 'Session notification',
              type: 'info',
              text: 'Your current session will end in the next 5 mins'
            })
            this.sessionAlertFlag += 1
            localStorage.setItem('sessionAlertFlag', this.sessionAlertFlag)
          }
        }, this.totalDelay)
      },
      logout: async function(){
        this.loader(true);
        localStorage.removeItem('userData')
        localStorage.removeItem('ticketFilters')
        localStorage.removeItem('sessionAlertFlag')
        this.$alertify({
          group: 'notification',
          title: 'Session timeout',
          type: 'success',
          text: 'Current session ended.'
        })
        await this.$sleep(1500);
        window.location.href = '/'
      }
    },
    mounted: function() {
      if(!localStorage.getItem('sessionAlertFlag'))
        localStorage.setItem('sessionAlertFlag', 1)
      if(this.$userData && localStorage.getItem('newLogin')){
        this.fetchNotifications()
        localStorage.removeItem('newLogin')
      }
      if(this.$userData && this.socket){
        this.socket.on('broadcast clients', (clients) => {
          this.$emit('updateOnlineClients', clients || [])
        })
        this.socket.on('broadcast chat notification', (chatNotification) => {
          if(this.$enableChats && this.$hideChatNotifications !== chatNotification.room.code){
            Vue.prototype.$alertify({
              group: 'notification',
              duration: 50000,
              title: `New message from ${chatNotification.sender.name}`,
              type: 'success',
              text: chatNotification.message
            })
          }
        })
      }
      this.initiateInterval(0, true)
    },
    created() {
      if(this.$userData && !this.$socket){
        this.socket = SocketioService.setupSocketConnection()
        Vue.prototype.$socket = this.socket
      }
      else if(this.$userData && this.$socket){
        this.socket = this.$socket
      }
    },
    beforeUnmount() {
      SocketioService.disconnect()
      Vue.prototype.$socket = false
      clearInterval(this.interval)
    }
  }
</script>
