import Vue from 'vue'
import App from './App.vue'
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import DatePicker from 'vue2-datepicker';
import VueSelect from 'vue-select'
import VModal from 'vue-js-modal'
import Editor from '@morioh/v-quill-editor'
import VueCompositionAPI from '@vue/composition-api'
import GAuth from 'vue-google-oauth2'
import TinyEditor from '@tinymce/tinymce-vue'

import router from './router'

import '@/assets/js/main.js'
import '@/assets/js/variables.js'
// import '@/assets/js/ticketing_extension.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-select/dist/vue-select.css';
import 'vue2-datepicker/index.css';
import '@/assets/css/main.css'
import '@/assets/css/main.scss'
import '@/assets/css/tickets.scss'
import '@/assets/css/profiles.scss'
import '@/assets/css/userProfile.css'
import '@/assets/css/tickets.css'
import '@/assets/css/comments.scss'
import '@/assets/css/notifications.css'
import '@/assets/css/notifications.scss'
import '@/assets/css/clients.scss'
import '@/assets/css/colors.css'
import '@/assets/css/tooltips.css'
import '@/assets/css/animate.css'
import '@/assets/css/toggle.scss'
import '@/assets/css/tinymce.scss'
import '@/assets/css/chats.scss'
// import '@/assets/css/ticketing_extension.scss'
import '@morioh/v-quill-editor/dist/editor.css';
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue-range-slider/dist/vue-range-slider.css'

Vue.use(VTooltip)
Vue.use(Notifications)
Vue.use(VModal)
Vue.use(Editor)
Vue.use(VueCompositionAPI)
Vue.use(require('vue-moment'));
Vue.use(require('vue-infinite-scroll'))
Vue.component('v-select', VueSelect)
Vue.component('tiny-editor', TinyEditor)
Vue.component('v-date-picker', DatePicker)
Vue.config.productionTip = false

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT,
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(GAuth, gauthOption)

new Vue({
  router,
  data: {
    userData: {}
  },
  methods: {
    loader: function(val){
      this.$emit('loader', val)
    },
    logout: async function(){
      this.loader(true);
      localStorage.removeItem('userData')
      localStorage.removeItem('ticketFilters')
      Vue.prototype.$alertify({
        group: 'notification',
        title: 'Session timeout',
        type: 'success',
        text: 'Current session ended.'
      })
      await this.$sleep(1500);
      window.location.href = '/'
    }
  },
  mounted: function(){
    this.userData = localStorage.getObject('userData')
    const login_timestamp = this.userData && this.userData.login_timestamp ? new Date(this.userData.login_timestamp) : false
    const current_date = new Date()
    const time_diff = 60 * 60 * 1000 * 2
    Vue.prototype.$userData = this.userData
    if(!(this.userData && this.userData.authToken) && !window.location.pathname.startsWith("/password-reset/") && !window.location.pathname.startsWith("/bxi/login"))
      this.$router.push('/login')
    else if (login_timestamp && ((current_date) - login_timestamp) > time_diff)
      this.logout(true)
  },
  render: h => h(App)
}).$mount('#app')
