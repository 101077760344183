<template>
  <div class="vue-template">
    <div class="App" id="mainDiv">
      <div v-if="this.$userData && this.$userData.authToken && this.disableCounters" v-bind:class="[this.loading ? 'row m-0 main-container' : 'row m-0']">
        <div class="col col-12 p-0">
          <AnnouncementComponent/>
        </div>
        <div class="col col-12 bg-primary-dark header-bar">
          <div class="col right-column header-bar-end">
            <!-- Top right navigation -->
            <img class="header-avatar" :src="$getAvatar(user.avatar)" v-on:click="toggleMenu('showUserMenu')">
            <div class="fas fa-bell header-icon" v-on:click="toggleMenu('showNotifications')"></div>
            <a href="/list" class="fas fa-stream header-icon" v-if="['Manager', 'Agent', 'Customer'].includes(user.role)"></a>
            <a href="/chats" class="fas fa-comment-alt header-icon" v-if="enableChats"></a>
          </div>

          <!-- Top left navigation -->
          <!-- Mobile -->
          <div class="col d-block d-lg-none d-md-none left-column header-bar-start">
            <div class="header-icon fas fa-bars mt-0 float-left" v-on:click="toggleMenu('showMainMenu')"></div>
            <a class="header-sm-logo float-left col-1 p-1" href="/">
              <img alt="iRipple" src="@/assets/images/iripple-logo-white.png">
            </a>
          </div>
          <!-- Desktop -->
          <div class="col d-none d-lg-block d-md-block left-column header-bar-start">
            <a class="header-logo col-1" href="/">
              <img alt="iRipple" src="@/assets/images/iripple-logo-white.png">
            </a>
            <div class="btn left-nav text-light" v-on:click="toggleMenu('showTicketsMenu')">
              Tickets <i class="fas fa-angle-down"></i>
            </div>
            <div class="btn left-nav text-light" v-on:click="toggleMenu('showClientsMenu')" v-if="['Admin', 'Manager'].includes(user.role)">
              Clients <i class="fas fa-angle-down"></i>
            </div>
            <div class="btn left-nav text-light" v-on:click="toggleMenu('showInternalMenu')" v-if="['Admin', 'Manager'].includes(user.role)">
              Internal <i class="fas fa-angle-down"></i>
            </div>
          </div>
        </div>
        <!-- Left side col -->
        <div v-if="!chatModuleOpen" class="col col-1 d-none d-lg-block d-md-block d-sm-block bg-light side-bar side-bar-sm">
          <SideMenuComponent
            @setTicketFilters="setTicketFilters($event)"
            @toggleSideOptions="toggleSideOptions($event)"
            @hideSideOptions="hideSideOptions($event)"
          ></SideMenuComponent>
        </div>
        <div class="col main-bar">
          <router-view
            :parameters="parameters"
            :onlineClients="onlineClients"
            :enableChats="enableChats"
            :isMobile="isMobile"
            @toggleSideOptions="toggleSideOptions($event)"
            @hideSideOptions="hideSideOptions($event)"
            @loader="loader($event)"
          />
          <div class="row d-block d-lg-none d-md-none">
            <div class="col">&nbsp;</div>
          </div>
          <transition name="fade">
            <UserMenuComponent @loader="loader($event)" v-if="menus.showUserMenu"></UserMenuComponent>
          </transition>
          <div class="cancel-menu" v-on:click="resetMenus('')" v-if="menus.show"></div>
          <transition name="fade">
            <NotificationComponent v-if="menus.showNotifications"></NotificationComponent>
          </transition>
          <transition name="fade">
            <MainMenuComponent v-if="menus.showMainMenu"></MainMenuComponent>
          </transition>
          <transition name="fade">
            <TicketsMenuComponent v-if="menus.showTicketsMenu"></TicketsMenuComponent>
          </transition>
          <transition name="fade">
            <ClientsMenuComponent v-if="menus.showClientsMenu"></ClientsMenuComponent>
          </transition>
          <transition name="fade">
            <InternalMenuComponent v-if="menus.showInternalMenu"></InternalMenuComponent>
          </transition>
          <div class="w-100 pb-5" v-if="isMobile">&nbsp;</div>
        </div>

        <!-- Right side col -->
        <div v-if="showSideOptions" class="col side-options bg-light d-none d-lg-block d-md-block p-2">
          <TicketsOptionsComponent
            @toggleSideOptions="toggleSideOptions($event)"
            @setTicketFilters="setTicketFilters($event)"
            @loader="loader($event)"
            v-if="showTicketsOptions"
          ></TicketsOptionsComponent>
        </div>
      </div>
      <router-view @loader="loader($event)" v-else/>
      <notifications group="notification" class="alerts-notification m-3 mt-4" />
      <NotificationAlertComponent @updateOnlineClients="updateOnlineClients($event)"/>
    </div>
    <transition name="fade">
      <div class="loader-screen bg-light" v-if="loading">
        <div>
          <i class="fas fa-spinner animate-spin"></i>
          <p class="animate-pulse">Please wait</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import NotificationComponent from './components/Notifications/Notifications.vue'
  import NotificationAlertComponent from './components/Notifications/NotificationAlert.vue'
  import UserMenuComponent from './components/Navigations/UserMenu.vue'
  import MainMenuComponent from './components/Navigations/MainMenu.vue'
  import TicketsMenuComponent from './components/Navigations/TicketsMenu.vue'
  import ClientsMenuComponent from './components/Navigations/ClientsMenu.vue'
  import InternalMenuComponent from './components/Navigations/InternalMenu.vue'
  import SideMenuComponent from './components/Navigations/SideMenu.vue'
  import TicketsOptionsComponent from './components/Navigations/OptionsPanel/TicketsOptions.vue'
  import AnnouncementComponent from './components/Navigations/Announcement.vue'

  export default {
    components: {
      NotificationComponent,
      NotificationAlertComponent,
      UserMenuComponent,
      MainMenuComponent,
      TicketsMenuComponent,
      ClientsMenuComponent,
      InternalMenuComponent,
      SideMenuComponent,
      TicketsOptionsComponent,
      AnnouncementComponent
    },
    data() {
      return {
        parameters: {},
        loading: true,
        user: this.$userData ? this.$userData.user : {},
        menus: {
          show: false,
          showNotifications: false,
          showMainMenu: false,
          showUserMenu: false,
          showTicketsMenu: false,
          showClientsMenu: false,
          showInternalMenu: false
        },
        showSideOptions: false,
        showTicketOptions: false,
        showTicketsOptions: false,
        rooms: [],
        users: [],
        onlineClients: [],
        enableChats: this.$enableChats
      }
    },
    methods: {
      loader: function(val){
        this.loading = val;
      },
      setTicketFilters: function(val){
        this.parameters = {
          ...this.parameters,
          filters: val
        }
      },
      toggleSideOptions: function(val){
        this.showSideOptions = !this.showSideOptions;
        this[val] = !this[val];
      },
      hideSideOptions: function(val){
        this.showSideOptions = false;
        this[val] = false;
      },
      toggleMenu: function(showElement){
        this.resetMenus(showElement)
        this.menus[showElement] = !this.menus[showElement];
        this.menus.show = !this.menus.show;
      },
      resetMenus: function(showElement){
        let keys = Object.keys(this.menus)
        for(let i = 0; i < keys.length; i++)
          this.menus[keys[i]] = showElement != keys[i] ? false : this.menus[keys[i]];
      },
      toggleChats: function(){
        if(this.enableChats)
          this.rooms.forEach(room => {
            this.$refs[room._id][0].closeChat(room)
          })
        this.enableChats = !this.enableChats
      },
      openChat: function(room){
        let id = [this.user._id, room._id].sort().join('-')
        let existing = this.rooms.find(r => r._id === id)
        let selected = this.rooms.find(r => r._id === id && r.active)
        if(selected){
          this.$refs[room._id][0].closeChat(room)
          return 0
        }
        if(existing){
          this.rooms = this.rooms.filter(r => r._id !== id)
          existing.active = true
          this.rooms.push(existing)
        }
        else
          this.rooms.push({
            _id: id,
            name: room.name,
            subName: room.email,
            active: true,
            avatar: room.avatar,
            chats: [],
            page: 1
          })
        let actives = 0
        for(let i = 0; i < this.rooms.length; i++){
          actives = actives + (this.rooms[this.rooms.length - i - 1].active ? 1 : 0)
          this.rooms[this.rooms.length - i - 1].active = actives <= 2 && this.rooms[this.rooms.length - i - 1].active
        }
      },
      closeChat: function(room){
        let selected = this.rooms.find(r => r._id === room._id)
        selected.chats = room.chats || []
        selected.active = false
      },
      async getUsers(){
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent,Customer`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.users = response.data.users.filter(r => r._id != this.user._id)
          switch (this.user.role) {
            case 'Manager':
              this.users = this.users.filter(r => r.roles === 'Customer' || r.roles === 'Agent' || (r.roles === 'Manager' && r.isAgent))
              break;
            case 'Agent':
              this.users = this.users.filter(r => r.roles === 'Customer')
              break;
            case 'Customer':
              this.users = this.users.filter(r => r.roles === 'Agent' || (r.roles === 'Manager' && r.isAgent))
              break;
            default:
          }
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
      },
      updateOnlineClients: function(clients){
        this.onlineClients = clients
      }
    },
    computed: {
      isMobile: function(){
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
          return true
        else
          return false
      },
      disableCounters: function(){
        return !this.$route.path.startsWith("/integrations")
      },
      chatModuleOpen: function(){
        return this.$route.name === 'chats'
      }
    },
    beforeCreate: function(){
      this.loading = true;
    },
    created: function(){
      if(['tickets', 'home'].includes(this.$route.name) || ['/', '/tickets', '/tickets/'].includes(window.location.pathname) && localStorage.getObject('ticketFilters') && window.location.pathname.substring(9,20) == "")
        this.toggleSideOptions('showTicketsOptions')
    },
    mounted: function(){
      this.loading = false
      if(this.user._id)
        this.getUsers()
      if(['tickets', 'home'].includes(this.$route.name) || ['/', '/tickets', '/tickets/'].includes(window.location.pathname) && localStorage.getObject('ticketFilters'))
        this.$alertify({
          group: 'notification',
          title: 'Ticket Filters',
          type: 'muted',
          text: 'Filter values reloaded from last search'
        })
    }
  }
</script>
