<template>
  <div class="overlay-box profile-holder card bg-light text-dark">
    <div class="vertical-center">
      <img class="profile-avatar" :src="$getAvatar(user.avatar)">
      <div class="profile-avatar-role">{{user.role | userRoles}}</div>
      <h5>{{user.name}}</h5>
      <small class="text-muted">{{user.email}}</small>
      <a href="/profile" class="btn btn-block btn-primary bg-primary-dark border-0 mt-4">
        View account
      </a>
      <div class="btn btn-block text-light bg-warm border-0 mt-2" v-on:click="logout" >
        Logout
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: this.$userData.user
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      logout: async function(){
        this.loader(true)
        localStorage.removeItem('userData')
        localStorage.removeItem('ticketFilters')
        localStorage.removeItem('ticketStatistics')
        this.$alertify({
          group: 'notification',
          title: 'Logout successful',
          type: 'success',
          text: 'Current session ended.'
        })
        await this.$sleep(1500);
        window.location.href = '/'
      },
    },
    filters: {
      userRoles(val){
        return val != 'Admin' ? val : 'Administrator'
      }
    }
  }
</script>
