<template>
  <div class="bg-primary-default p-1 text-center row" :class="this.showAlert ? '' : 'd-none'">
    <div class="col">
      <small>{{announcement.message}}</small>
    </div>
    <div class="col-auto">
      <div class="btn btn-sm text-light" @click="closeAlert()">
        <i class="fas fa-close"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        announcement: {},
        showAlert: true
      }
    },
    methods: {
      getAnnouncement: function(){
        this.announcement = {
          message: "ITS Maintenance - ITS will be under maintenance from Saturday, July 16, 10 PM, until Sunday, July 17, 2022, 6 AM. It will not be available during those hours.",
          _id: `global-alert-1657882131811`,
          start_date: 1657882131811,
          end_date: (new Date(1657882131811)).setDate((new Date(1657882131811)).getDate() + 1)
        }

        if(localStorage.getItem('its-global-alert') == this.announcement._id){
          this.closeAlert()
        }
        else{
          if(this.checkAlert())
            this.showAlert = true
          else
            this.closeAlert()
        }
      },
      closeAlert: function(){
        this.showAlert = false
        localStorage.setItem('its-global-alert', this.announcement._id)
      },
      checkAlert: function(){
        var show = true
        if(!(this.announcement.start_date && this.announcement.start_date < (new Date)))
          show = false
        if(!(this.announcement.end_date && this.announcement.end_date > (new Date)))
          show = false
        return show
      }
    },
    mounted: function() {
      this.getAnnouncement()
    }
  }
</script>
