<template>
  <div class="overlay-box notification-holder card bg-light text-dark">
    <div class="notification-label">
      Your notifications
    </div>
    <div v-infinite-scroll="getNotifications" infinite-scroll-disabled="busy" infinite-scroll-distance="1000" infinite-scroll-throttle-delay="2000">
      <a :href="`/tickets/${notification.ticket ? notification.ticket.ticketNumber : ''}`" class="row" v-for="notification in notifications"  v-bind:key="notification._id">
        <img class="col notification-avatar" v-bind:src="`https://support.iripple.com/${notification.sender.avatar}`">
        <div class="col">
          <div class="notification-header">
            {{notification.content.title}}
            <span class="text-muted">{{notification.createdAt | dateEstimate}}</span>
          </div>
          <small class="notification-body">
            <span class="text-muted">{{notification.sender.name}}</span><br>
            {{(notification.content.simplifiedMessage || notification.content.message)}}
          </small>
        </div>
      </a>
      <li v-if="page < notificationTotal && notificationCount > 0" class="row">
        <small class="col col-12 text-muted text-center p-1 m-0">
          Loading more notifications &nbsp;
          <h6 class="fas fa-spinner animate-spin"></h6>
        </small>
      </li>
      <li v-if="notifications.length === 0" class="vertical-center h-100">
        <h6 class="col col-12 text-muted text-center p-3">
          No available notifications &nbsp;
          <h6 class="fas fa-exclamation-circle"></h6>
        </h6>
      </li>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        id: this.$userData.user._id,
        page: 1,
        busy: false,
        notifications: [],
        notificationCount: 0,
        notificationTotal: 0
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      async getNotifications() {
        this.busy = true;
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/notification/get/${this.id}/${this.page}`,{
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.notifications = this.notifications.concat(response.data.notifications)
          this.notificationCount = response.data.notifications.length;
          this.notificationTotal = response.data.totalPage;
          if (this.page < response.data.totalPage)
            this.busy = false;
          else
            this.busy = true;
          this.page += 1;
        } catch (error) {
          console.log(error)
        }
      }
    },
    mounted: function() {
      this.getNotifications()
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      },
      capitalize(val) {
        return val.capitalize()
      }
    }
  }
</script>
