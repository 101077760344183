import Vue from 'vue'

Storage.prototype.setObject = function(key, value) {
  this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function(key) {
  return JSON.parse(this.getItem(key));
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

Vue.prototype.$getAvatar = function (pic) {
  if(pic)
    return require('../../assets'+pic)
}
