<template>
  <div class="overlay-box tickets-menu menu-holder card bg-light text-dark">
    <div class="vertical-center">
      <a href="/" class="list-item" v-bind:class="[menuState('home,ticket')]">Tickets</a>
      <a href="/dashboard" class="list-item" v-bind:class="[menuState('dashboard')]">Dashboard</a>
      <a href="/reports" class="list-item" v-bind:class="[menuState('report')]">Reports</a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        user: this.$userData.user
      }
    },
    methods: {
      menuState: function(val){
          return val.split(',').some(v => this.$route.name.includes(v)) ? 'active' : ''
      }
    }
  }
</script>
